export const artistsJSON = [
  {
    artist: "Eddie",
    socialLink: "https://store.fortherecordbook.net/pages/decoy-industries",
  },
  {
    artist: "Coy",
    socialLink: "https://store.fortherecordbook.net/pages/wet-bucket",
  },
  {
    artist: "FMDA",
    socialLink: "https://store.fortherecordbook.net/pages/far-metal-dark-arts",
  },
  {
    artist: "Richard",
    socialLink: "https://store.fortherecordbook.net/pages/olive-drab",
  },
  {
    artist: "Brad",
    socialLink:
      "https://store.fortherecordbook.net/pages/veterans-art-collective",
  },
  {
    artist: "Adam",
    socialLink: "https://store.fortherecordbook.net/pages/reaper-ink-designs",
  },
  {
    artist: "Ben",
    socialLink: "https://store.fortherecordbook.net/pages/ben-cantwell",
  },
  {
    artist: "Kriss",
    socialLink: "https://store.fortherecordbook.net/pages/kriss-moye",
  },
  {
    artist: "Coy2",
    socialLink: "https://store.fortherecordbook.net/pages/wet-bucket",
  },
  {
    artist: "Tebo",
    socialLink: "https://store.fortherecordbook.net/pages/tebos-art",
  },
  {
    artist: "Ben2",
    socialLink: "https://store.fortherecordbook.net/pages/ben-cantwell",
  },
  {
    artist: "Jacob",
    socialLink: "https://store.fortherecordbook.net/pages/jacob-ferguson",
  },
  {
    artist: "Adam2",
    socialLink: "https://store.fortherecordbook.net/pages/reaper-ink-designs",
  },
];
