import { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { About } from "./pages/About";
import { Artists } from "./pages/Artists";

export const App = () => {
	return (
		<Fragment>
			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route path="/about" component={About} />
				<Route path="/artists" component={Artists} />
				<Route render={() => <h1 className="tc">404 not found</h1>} />
			</Switch>
			<Footer />
		</Fragment>
	);
};

const Footer = () => {
	return (
		<footer className="pv3 ph3 ph5-m ph6-l mid-gray tc text-focus-in-3 ">
			<div className=" tc">
				<a
					className="link i hover-dark-green black "
					href="mailto:Contact@fortherecordbook.net?subject=Information Request&body= Good -insert TOD-, \n\n I am writting you today to request information; \n\n -insert request- \n\n -best\n -insert name-"
				>
					<span className="fw5">Contact@fortherecordbook.net</span>
				</a>
			</div>

			<a
				className="link hover-dark-green mid-gray  tc"
				href="https://www.instagram.com/for.the.record.books/"
				title="Instagram"
				target="_blank"
				rel="noopener noreferrer"
			>
				<svg
					className="dib h2 w2 mv3"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 16 16"
					fillRule="evenodd"
					clipRule="evenodd"
					strokeLinejoin="round"
					strokeMiterlimit="1.414"
				>
					<path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.048 3.233c-.036.78-.166 1.203-.276 1.485-.145.374-.318.64-.598.92-.28.28-.546.453-.92.598-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.01-3.233-.048c-.78-.036-1.203-.166-1.485-.276-.374-.145-.64-.318-.92-.598-.28-.28-.453-.546-.598-.92-.11-.282-.24-.705-.276-1.485C1.45 10.39 1.44 10.136 1.44 8s.01-2.39.048-3.233c.036-.78.166-1.203.276-1.485.145-.374.318-.64.598-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276C5.61 1.45 5.864 1.44 8 1.44zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z" />
				</svg>
			</a>
			<small className="f6 db tc">
				© {new Date().getFullYear()} <b className="ttu">FTR Publishing</b>., All Rights
				Reserved
			</small>
			<div className="tc mt3">
				<span title="Info" className="f6 dib ph2 link mid-gray ">
					100% - Veteran Owned and Operated
				</span>
			</div>
		</footer>
	);
};
