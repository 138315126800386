import { Link } from "react-router-dom";
import book from "../assets/img/logo/ft-recordbook.png";
import girl from "../assets/img/girl-sticker.png";

export const HomePage = () => {
  return (
    <main className="cf pa3 pa4-m pa3-l mw9 center tc">
      <div className=" w-100 text-focus-in ">
        <div className="f2 f1-l lh-title  ">
          - For The -
          <br className="dn db" />
          Record
          <p className="f6 i tracked">Publishing House</p>
          <time className="ttu  black f6 text-focus-in-2">
            Recruitment Status :{" "}
          </time>
          <time className="f6 ttu tracked b black  dark-green text-focus-in-3">
            OPEN
          </time>
          {/* <a className="link" href="https://store.fortherecordbook.net">
            <div className="courier mt2 f4 b yellow bg-black w-40-ns ba bw3 center">
              <span className=" tracked"> VOL II on sale soon </span>
              <br />
              <span className="red  f5">
                Click here to subscribe to the newsletter and be the first to
                know
              </span>{" "}
            </div>
          </a> */}
        </div>
      </div>
      {navbar()}
      <a href="https://store.fortherecordbook.net">
        <img
          src={girl}
          className="text-focus-in-3 w-50-ns w-100"
          alt="FOR THE RECORD BOOK"
        />
      </a>
    </main>
  );
};

const navbar = () => {
  const linkStyle =
    "link hover-dark-green grow   f2-ns f4 black dib text-focus-in-2 ";
  return (
    <nav className="tc w-50 pa3 center justify-around flex ">
      <a href="https://store.fortherecordbook.net">
        <span className={linkStyle}> STORE </span>
      </a>

      <Link className="ph3 " to="/artists">
        <span className={linkStyle}>ARTISTS</span>
      </Link>

      <a
        className="pr3"
        href="https://store.fortherecordbook.net/pages/community"
      >
        <span className={linkStyle}> PR </span>
      </a>

      <Link className=" " to="/about">
        <span className={linkStyle}> ABOUT </span>
      </Link>
    </nav>
  );
};
