import { Link } from "react-router-dom";
import logo from "../assets/img/logo/logo.jpg";

export const About = () => {
	return (
		<main className="cf pa3 pa4-m pa5-l mw9 center tc about-page">
		<h1> FOR THE RECORD </h1>
			<Link className="link black" to="/">
				<div className="mb4">
					<img src={logo} className="grow h4  text-focus-in-3 grow " />
					<br />
					<span className="">HOME</span>
				</div>
			</Link>
			<article className="cf ph3 ph5-ns pv5">
				<header className="fn fl-ns w-50-ns pr4-ns text-focus-in">
					<h1 className="f2 lh-title fw9 mb3 mt0 pt3 bt bw2">On Record</h1>
					<h2 className="f3 mid-gray lh-title ">
						An excerpt from our recruitment letter
					</h2>
					Compiling artwork from our veteran community to create a tattoo flash book.
				</header>
				<div className="fn fl-ns w-50-ns text-focus-in-2">
					<p className="f5 lh-copy measure mt0-ns">
						The creativity of the GWOT era veteran has come through in many lights
						and it is time that a new standard in one of our artist's reflections
						is set; the Tattoos. The time of Sailor Jerry and likes, although
						timeless, are not contemporary to our experiences and we have dozens
						upon scores of talented artists we hope to recruit to help reflect our
						time.
					</p>
					<p className="f5 lh-copy measure">
						The flagship of this project will be a single item: a physical book;
						containing a collection of flash work by artists from all reaches of
						the GWOT. We are dedicated to providing the best page quality, a hard
						cover, gilded spine and serialization to give the owner a
						state-of-the-art volume. The book will be designed to be bent over a
						copier as we want it to be functional for tattoo artists at the end of
						the day; making it equally fitting to be on a coffee table or in the
						workstation of a tat shop right outside base.
					</p>
					<p className="f5 lh-copy measure">
						If you know of anyone else who you think would like to be involved
						please do not hesitate to suggest them or put us in direct contact, as
						we plan on doing several volumes.
					</p>
					<p className="measure">
						<br className="dn db " />
						<a
							className="link i hover-dark-green black "
							href="mailto:recruitment@fortherecordbook.net?subject=Information Request&body= Good -insert TOD-, \n\n I am writting you today to inquire about joining the project; \n\n -insert request- \n\n -best\n -insert name-"
						>
							<span className="fw5 pt3 ">
								recruitment@fortherecordbook.net
							</span>
						</a>
					</p>
				</div>
			</article>
		</main>
	);
};
