import { Link } from "react-router-dom";
import { artistsJSON } from "../assets/json/artists.js";
import logo from "../assets/img/logo/logo.jpg";

export const Artists = () => {
  const ArtistCard = ({ artist, socialLink }) => {
    console.log(artist);
    return (
      <div className="">
        <a href={socialLink} className="fl w-50 w-25-l link overflow-hidden">
          <div
            className="grow aspect-ratio--4x6 "
            style={{
              backgroundImage: `url(/artists-signitures/${artist}.png)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </a>
      </div>
    );
  };

  const shuffle = () => {
    let array = artistsJSON;
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <main className="cf mw9 center tc about-page">
      <Link className="link black" to="/">
        <div className="mv3">
          <img src={logo} className="grow h4  text-focus-in-3 grow " />
          <br />
          <span className="">HOME</span>
        </div>
      </Link>
      {shuffle(artistsJSON).map((a) => ArtistCard(a))}
    </main>
  );
};
